.hairsindia-header-container .header-logo-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}
.header-title-image {
  width: 98%;
}
.Indian-Human-Hair-Exports {
  background-color: #48940f;
  color: #fff;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 0px 0px 10px 10px;
}
.hairsindia-header-menu {
  background-color: #fff;
  overflow: hidden;
}
.hairsindia-header-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.hairsindia-header-menu li {
  padding: 8px 20px;
  position: relative;
}
.hairsindia-header-menu ul li:after {
  /* border-right: 1px solid black; */
  /* content: "|"; */
  position: absolute;
  right: 0;
  width: 0px;
  height: 20px;
  color: red;
}
.hairsindia-header-menu ul span {
  width: 3px;
  height: 18px;
  background-color: #ff6600;
}
.hairsindia-header-menu ul li:last-child {
  border-right: none;
}
.hairsindia-header-menu a {
  font-family: var(--font-family1);
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.hairsindia-header-menu {
  display: flex;
  align-items: center;
  gap: 2px;
}
.hairsindia-header-menu .hairsindia-order-here-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #ff6600;
  padding: 3px 15px 5px;
  border-radius: 30px;
  transition: all 0.2s linear;
  border: 1px solid transparent;
}
.hairsindia-header-menu .hairsindia-order-here-btn:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.hairsindia-header-menu .hairsindia-order-reviews-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #48940f;
  padding: 3px 15px 5px;
  border-radius: 30px;
  transition: all 0.2s linear;
  border: 1px solid transparent;
  margin-left: 10px;
}
.hairsindia-header-menu .hairsindia-order-reviews-btn:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}
.hairsindia-menu-mobile-view {
  display: none;
}
.hairsindia-header-top-content {
  width: 80%;
}
.hairsindia-header-menu-mobile {
  display: block;
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: left 0.3s ease-in-out 0.3s;
}
.hairsindia-header-menu-mobile.open {
  left: 0;
  display: block;
  transition-delay: 0.3s;
}
.hairsindia-header-menu-mobile ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.hairsindia-header-menu-mobile li {
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.hairsindia-header-menu-mobile li:hover {
  background-color: #f0f0f0;
}
.hairsindia-header-menu-mobile .nav-link {
  display: block;
  color: black;
  text-decoration: none;
}
.hairsindia-header-menu-mobile .hairsindia-order-here-btn {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  cursor: pointer;
}
.hairsindia-header-menu-mobile .hairsindia-order-here-btn:hover {
  background-color: #ff6600;
}
.hairsindia-header-menu-mobile .hairsindia-order-reviews-btn {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background-color: #48940f;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
}
.hairsindia-header-menu-mobile .hairsindia-order-reviews-btn:hover {
  background-color: #48940f;
}
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  transition: left 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: fixed;
  left: 5px;
  top: 30px;
  z-index: 1001;
  cursor: pointer;
  background-color: #ff6600;
  padding: 15px 1px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.mobile-menu-toggle:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.mobile-menu-toggle.open {
  left: 250px;
}
.mobile-menu-toggle.open svg {
  transform: rotate(180deg);
}
.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.mobile-menu-overlay.open {
  display: block;
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .hairsindia-header-top-content {
    width: 70%;
  }
}

@media screen and (max-width: 1023px) {
  .hairsindia-header-top {
    align-items: center !important;
    padding: 0 20px;
  }
  .Indian-Human-Hair-Exports {
    font-size: 17px !important;
    padding: 1px 6px;
  }
}

@media screen and (max-width: 768px) {
  .hairsindia-header-menu {
    display: none;
  }
  .hairsindia-menu-mobile-view {
    display: block;
    position: absolute;
  }
  .mobile-menu-toggle {
    display: block;
  }
  .hairsindia-header-container .header-logo-image {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 576px) {
  .Indian-Human-Hair-Exports {
    font-size: 13px !important;
    padding: 0px 5px;
    line-height: 21px !important;
  }
}
