.sections-divider {
  width: 100%;
  height: 70px;
  background-color: #3a6108;
}
@media screen and (max-width: 768px) {
  .sections-divider {
    height: 40px;
  }
}
