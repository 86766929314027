.hairsindia-product {
  background-image: url(../../assets/images/homepage/product/bg.png);
  width: 100%;
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.hairsindia-prod-nat {
  width: 130px;
  height: auto;
  position: absolute;
  left: 40px;
  top: 20px;
}
.hairsindia-product-text-section {
  position: absolute;
  bottom: -10%;
  right: -35%;
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-bottom: 10px;
  border-bottom: 6px solid #ff6600;
  width: fit-content;
  height: fit-content;
}
.hairsindia-product-text-section span {
  color: white;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 50px;
  transform: scaley(1.1);
}
.hairsindia-product-text {
  position: absolute;
  bottom: 100px;
  right: 100px;
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-bottom: 10px;
  border-bottom: 6px solid #ff6600;
  width: fit-content;
  height: fit-content;
  display: none;
}
.hairsindia-product-text span {
  font-family: var(--font-family1);
  color: white;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 50px;
  transform: scaley(1.1);
}
.big-image-container {
  border: 4px dashed white;
  padding: 10px;
  display: inline-block;
  max-width: 650px;
}
.big-image-container.hairsindia-wigs {
  max-width: 500px;
}
.iiz.hairsindia-product-big-image {
  display: block;
}
.hairsindia-product-big-image {
  width: 100%;
  height: auto;
  background-color: #6bb40e;
  min-width: 200px;
  object-fit: cover;
  border: 7px solid #033703;
}
.hairsindia-product-big-image-wig {
  width: 100%;
  height: auto;
  background-color: #6bb40e;
  min-width: 200px;
  border: 7px solid #033703;
  object-fit: cover;
}
.iiz.hairsindia-product-big-image-wig {
  display: block;
}
.small-image-container {
  border: 7px solid #033703;
  padding: 5px;
  display: inline-block;
}
.hairsindia-product-small-image label {
  font-family: var(--font-family1);
  transition: all 0.3s linear;
  font-weight: 400;
}
.hairsindia-product-small-image label:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}
.hairsindia-product-small-image {
  justify-content: space-between;
}
.hairsindia-product-bg-image {
  width: 100%;
  height: auto;
  position: relative;
}
.hairsindia-product-small-image {
  width: 80px;
  height: 100px;
  object-fit: cover;
  background-color: #6bb40e;
  cursor: pointer;
}
.hairsindia-swiper-product {
  font-family: var(--font-family1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  padding: 8px 30px !important;
}

.foundation-card-next {
  position: absolute;
  right: -50px;
  z-index: 999;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  background-color: #ff6600;
  color: #fff;
  transition: all 0.3s linear;
  animation: heartbeat 2s infinite;
}
.foundation-card-prev {
  position: absolute;
  left: -50px;
  z-index: 999;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  background-color: #ff6600;
  color: #fff;
  transition: all 0.3s linear;
  animation: heartbeat 2s infinite;
}
.foundation-card-next:hover,
.foundation-card-prev:hover {
  transition: all 0.3s linear;
  background-color: #fff;
}
.foundation-card-next:hover .arrow-icon,
.foundation-card-prev:hover .arrow-icon {
  transition: all 0.3s linear;
  fill: #000;
}
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
  }
  20%,
  80% {
    transform: scale(1.03) rotate(0deg);
  }
  40%,
  60% {
    transform: scale(1.07) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(0deg);
  }
}

.hairsindia-product-swiper-btns {
  transform: translateY(-20px);
}

@media screen and (min-width: 1540px) {
  .hairsindia-product-big-image,
  .hairsindia-product-big-image-wig {
    width: 520px;
  }
  .hairsindia-product-text {
    right: 150px;
    bottom: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .hairsindia-product-text {
    display: flex;
  }
  .hairsindia-product-text-section {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .hairsindia-product-small-image {
    justify-content: flex-start;
  }
  .hairsindia-product-text-section {
    top: -20%;
    bottom: 0;
    flex-direction: row;
    gap: 15px;
    right: -15%;
  }
  .hairsindia-product-text-section span {
    font-size: 36px;
  }
  .hairsindia-product-small-image {
    width: 50px;
    height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .hairsindia-prod-nat {
    width: 100px;
  }
  .hairsindia-product-text-section {
    position: relative;
    margin-bottom: 15px;
    right: 0;
  }
  .hairsindia-product-image-section {
    flex-direction: column;
  }
  .hairsindia-product-big-image,
  .hairsindia-product-big-image-wig {
    border: 3px solid #033703;
  }
  .hairsindia-product-small-image {
    width: 38px;
    height: 45px;
  }
  /* .hairsindia-product-swiper-btns {
    transform: translateY(-35px);
  } */
}

@media screen and (max-width: 768px) {
  .hairsindia-product {
    height: auto;
    padding: 70px 0 50px;
  }
  .hairsindia-prod-nat {
    top: 15px;
    width: 75px;
  }
  .hairsindia-product-text-section {
    padding-bottom: 5px;
    border-bottom: 3px solid #ff6600;
  }
  .hairsindia-product-text-section span {
    font-size: 30px;
  }
  .big-image-container.hairsindia-wigs {
    width: 90%;
  }
  .big-image-container {
    width: 90%;
    height: auto;
    border: 3px dashed white;
    padding: 4px;
  }
  .hairsindia-product-big-image,
  .hairsindia-product-big-image-wig {
    height: auto;
    width: 100% !important;
  }
  /* .wigs-product {
    height: 880px !important;
  } */
  .small-image-container {
    border: 3px solid #033703;
    padding: 3px;
  }
}

@media screen and (max-width: 480px) {
  .hairsindia-product-image-section {
    margin-top: 60px;
  }
  .hairsindia-product-text-section span {
    line-height: 30px;
    font-size: 20px;
  }
  .hairsindia-product-text-section {
    margin: 0;
  }
  /* .wigs-product {
    height: 650px !important;
  } */
  .hairsindia-product {
    padding: 40px 0;
  }
}


.qqqqqqqqq{
  max-width: 1300px;
}