.hairsindia-order-content h1 {
  font-size: 50px;
  font-family: var(--font-family3);
}
.hairsindia-order-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 0;
}
.hairsindia-order-content-left label {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}
.hairsindia-order-content-left {
  width: 70%;
  font-family: var(--font-family1);
}
.hairsindia-content-right {
  width: 30%;
  font-family: var(--font-family1);
}
.hairsindia-order-container {
  background-image: url(../../assets/images/homepage/order/green.png);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hairsindia-natrajar {
  width: 130px;
  height: auto;
  position: absolute;
  right: 40px;
  top: 20px;
}
.selection-section > select {
  width: 100%;
  min-width: 135px;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  font-family: var(--font-family1);
}

.selection-section > select:focus {
  box-shadow: 2px 0px gray, 0px 2px gray, -2px 0px gray, 0px -2px gray !important;
}
.order-numbers .order-minus,
.order-numbers .order-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 45px;
  height: 34px;
  background: #fff;
  border-radius: 0;
  padding: 5px;
  border: 1px solid #eee;
  vertical-align: middle;
  color: #335506;
  line-height: 28px;
  cursor: pointer;
}
.order-numbers input {
  height: 34px;
  width: 45px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #eee;
  border-radius: 0;
  display: inline-block;
  vertical-align: middle;
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: auto !important;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 4px !important;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background-color: rgba(159, 159, 159, 0.5);
  border-radius: 4px !important;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background-color: #747474;
  border-radius: 4px !important;
  background-clip: content-box;
  border: 3px solid transparent;
}
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container {
  min-height: 42px !important;
}
.ag-root.ag-layout-auto-height {
  width: 100% !important;
}
.ag-sort-indicator-container {
  display: none !important;
}
.ag-header-cell-text {
  font-size: 14px !important;
}
.ag-header-cell.ag-header-cell-sortable.ag-focus-managed,
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
  padding: 0 8px;
}
.ag-header-cell.ag-column-first.ag-header-cell-sortable.ag-focus-managed {
  padding-left: 12px;
}
.ag-header-cell.ag-column-last.ag-header-cell-sortable
  > .ag-header-cell-resize {
  display: none;
}
.ag-ltr .ag-header-cell-resize {
  right: 0 !important;
}
.form-group input,
.form-group select {
  height: 38px;
  font-size: 16px;
  color: #000;
  border-radius: 4px;
}
.form-group textarea {
  font-size: 16px;
  color: #000;
  border-radius: 4px;
  overflow-y: auto;
  resize: none;
  padding: 4px 10px;
  height: 80px;
}
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  box-shadow: 2px 0px gray, 0px 2px gray, -2px 0px gray, 0px -2px gray !important;
  border: 1px solid gray;
}
.hairsindia-orders-add-btn {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #6bb40e;
  padding: 9px 20px 7px;
  border-radius: 30px;
  line-height: 17px;
  font-weight: 600;
  border: 1px solid #6bb40e;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
  font-size: 16px;
}
.hairsindia-orders-add-btn:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}
.hairsindia-get-quote {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #6bb40e;
  padding: 9px 20px 7px;
  border-radius: 30px;
  line-height: 17px;
  font-weight: 600;
  border: 1px solid #6bb40e;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
  font-size: 16px;
}
.hairsindia-get-quote:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}
.hairsindia-order-title {
  max-width: 480px;
  margin-left: 70px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.table-responsive .table thead th {
  padding: 10px 13px;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  width: 1%;
}
.table-responsive .table tbody td {
  padding: 2px 13px;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  width: 1%;
}
.table-responsive .table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1540px) {
  .hairsindia-order-list-container {
    width: 1400px;
  }
}
@media screen and (min-width: 1200px) {
  .chi-select-bx {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1023px) {
  .chi-select-bx {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
  }
  .chi-select-bxs {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
  }
  .hairsindia-order-title {
    max-width: 380px;
    margin-left: 40px;
  }
  .hairsindia-order-list-container {
    flex-direction: column;
  }
  .hairsindia-order-content-left,
  .hairsindia-content-right {
    width: 100%;
  }
  .hairsindia-natrajar {
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .chi-select-bx,
  .chi-select-bxs {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
  }
  .hairsindia-natrajar {
    width: 75px;
  }
  .hairsindia-order-content {
    gap: 20px;
  }
  .ag-theme-alpine {
    padding-bottom: 20px;
    border-bottom: 1px solid white;
  }
}
@media screen and (max-width: 480px) {
  .hairsindia-order-title {
    max-width: 240px;
    margin-left: 10px;
  }
  .hairsindia-natrajar {
    right: 25px;
  }
}
