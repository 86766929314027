.hairsindia-review-main-page {
  background-image: url(../../assets/images/homepage/product/bg.png);
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family1);
  padding-bottom: 60px;
}

.hairsindia-main-page {
  width: calc(100% - 20px);
  max-width: 1300px;
  min-height: 460px;
  margin: 0 auto;
  margin-top: 180px;
}

.hairsindia-review-main-sculp {
  width: 130px;
  height: auto;
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 1;
}

.chi-top-text:before {
  content: url(../../assets/images/reviews/main-page/chat1.png);
  position: absolute;
  bottom: 24px;
  width: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

.chi-top-text1:before {
  content: url(../../assets/images/reviews/main-page/chat1.png);
  position: absolute;
  bottom: 20px;
  width: 100px;
}

.hi-main-text:before {
  content: url(../../assets/images/reviews/main-page/chat.png);
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}
.hi-main-text1:before {
  content: url(../../assets/images/reviews/main-page/chat.png);
  position: absolute;
  bottom: 50px;
  width: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

.hairsindia-review-menu span {
  font-family: var(--font-family1);
  border-radius: 2px;
  padding: 10px 35px;
  background-color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-size: 16px;
}

.hairsindia-review-menu span:hover {
  color: #fff;
  background-color: #000;
}

/* .hairsindia-review-menu span:after{
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  background: linear-gradient(to right, black 10%, white 1%);
}

.hairsindia-review-menu span:hover:after{
  width: 100%;
  transform: scaleX(.2);
  transform-origin: bottom left;
} */

/* .hairsindia-review-main-section {
  width: 100%;
  height: auto;
  margin-top: 180px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
} */
.hairsindia-review-section {
  background-image: url(../../assets/images/homepage/product/bg.png);
  width: 100%;
  height: auto;
  padding: 100px 10px 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
}
.hairsindia-review-video-title span,
.hairsindia-review-photo-title span {
  font-size: 120px;
  font-weight: 600;
  color: #000;
  line-height: 120px;
  font-family: var(--font-family1);
}
.hairsindia-review-video-title span:after,
.hairsindia-review-photo-title span:after {
  content: "Reviews";
  position: absolute;
  font-size: 24px;
  padding: 0 10px 0 0px;
  transform: translate(-23px, 59px);
  background: #ffc303;
  line-height: 22px;
}

.hairsindia-review-big-video {
  width: 550px;
  height: 660px;
  min-width: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  border: 4px solid #000;
  border-radius: 20px;
}
/* .hairsindia-review-big-video:before {
  content: "";
  border: 3px solid #fff;
  width: 100%;
  position: absolute;
  height: 100%;
} */
.hairsindia-review-big-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  animation: fadeInOut 0.5s ease-in-out;
  pointer-events: auto;
  z-index: 1;
  display: none;
}
.hairsindia-review-big-video:hover .play-icon-container {
  display: flex;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.s-play-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  pointer-events: none;
}

.s-play-icon {
  width: 24px;
  height: 24px;
  color: white;
}

.play-icon,
.pause-icon {
  width: 45px;
  height: 45px;
  color: #fff;
}

.small-video-container {
  border: 2px solid #033703;
  display: inline-block;
  width: 80px;
  height: 95px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.small-video-container.active,
.hairsindia-swiper-image.active {
  border-color: #fff;
}

.photo-review-section {
  width: 550px;
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.photo-review-section img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.hairsindia-swiper-image {
  border: 2px solid #033703;
  display: inline-block;
  width: 80px;
  height: auto;
  border-radius: 4px;
  overflow: hidden;
}
.hairsindia-swiper-image img,
.hairsindia-review-small-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.hairsindia-review-section
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  max-width: 550px !important;
  margin-top: 20px;
}
.hairsindia-photo-swiper-btns {
  transform: translateY(-49px);
  z-index: 1;
}
.hairsindia-photo-swiper-btns .foundation-card-next {
  right: 5px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}
.hairsindia-photo-swiper-btns .foundation-card-prev {
  left: 5px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}
.hairsindia-video-swiper-btns {
  transform: translateY(-49px);
  z-index: 1;
}
.hairsindia-video-swiper-btns .foundation-card-next {
  right: -20px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}
.hairsindia-video-swiper-btns .foundation-card-prev {
  left: -20px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}
.hairsindia-gif-image {
  width: 130px;
  height: 130px;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .hairsindia-review-main-sculp {
    width: 100px;
  }
  .hairsindia-gif-image {
    width: 90px;
    height: 90px;
  }
  .play-icon-container {
    display: none;
  }
  .hairsindia-review-big-video:hover .play-icon-container {
    display: none;
  }
}

/* @media screen and (min-width: 767px) {
  .hairsindia-main-image{
    max-width: 650px;
  }
} */
@media screen and (max-width: 768px) {
  .hairsindia-review-main-sculp {
    top: 15px;
    width: 75px;
    right: 15px;
  }
  .hairsindia-gif-image {
    top: 20px;
    width: 72px;
    height: 72px;
    left: 15px;
  }

  .hairsindia-review-video-title span,
  .hairsindia-review-photo-title span {
    font-size: 60px;
    line-height: 80px;
  }
  .hairsindia-review-video-title span:after,
  .hairsindia-review-photo-title span:after {
    content: "Reviews";
    position: absolute;
    font-size: 18px;
    padding: 0 10px 0 0px;
    transform: translate(-15px, 35px);
    background: #ffc303;
    line-height: 17px;
    padding-top: 2px;
  }
}

@media screen and (max-width: 576px) {
  .photo-review-section,
  .hairsindia-review-big-video,
  .hairsindia-video-review-section {
    width: 100%;
  }
  .hairsindia-review-big-video {
    max-height: 500px;
  }
  .hairsindia-photo-swiper-btns .foundation-card-next,
  .hairsindia-video-swiper-btns .foundation-card-next {
    right: -8px;
  }
  .hairsindia-photo-swiper-btns .foundation-card-prev,
  .hairsindia-video-swiper-btns .foundation-card-prev {
    left: -8px;
  }
  .hairsindia-swiper-image img {
    width: 60px;
  }
  .hairsindia-swiper-image {
    width: 62px;
  }
  .small-video-container {
    width: 62px;
    height: 72px;
  }
  .hairsindia-photo-swiper-btns,
  .hairsindia-video-swiper-btns {
    transform: translateY(-38px);
  }
  .hairsindia-review-menu span {
    font-size: 15px;
    padding: 7px 19px;
  }
}
